import React from "react";
import "./skills.css";

const Backend = () => {
  return (
    <div className="skills__content">
      <h3 className="skills__title"></h3>

      <div className="skills__box">
        {/*  */}
        <div className="skills__group">
          {/*  */}
          <div className="skills__data">
            <i className="bx bx-badge-check"></i>

            <div>
              <h3 className="skills__name">Java</h3>
              <span className="skills__level">Intermediate</span>
            </div>
          </div>
          {/*  */}

          {/*  */}
          <div className="skills__data">
            <i className="bx bx-badge-check"></i>

            <div>
              <h3 className="skills__name">Python</h3>
              <span className="skills__level">Basic</span>
            </div>
          </div>
          {/*  */}

          {/*  */}
          <div className="skills__data">
            <i className="bx bx-badge-check"></i>

            <div>
              <h3 className="skills__name">AWS</h3>
              <span className="skills__level">Basic</span>
            </div>
          </div>
          {/*  */}
        </div>
        {/*  */}
        <div className="skills__group">
          {/*  */}
          <div className="skills__data">
            <i className="bx bx-badge-check"></i>

            <div>
              <h3 className="skills__name">Firebase</h3>
              <span className="skills__level">Basic</span>
            </div>
          </div>
          {/*  */}

          {/*  */}
          <div className="skills__data">
            <i className="bx bx-badge-check"></i>

            <div>
              <h3 className="skills__name">Git</h3>
              <span className="skills__level">Intermediate</span>
            </div>
          </div>
          {/*  */}

          {/*  */}
          <div className="skills__data">
            <i className="bx bx-badge-check"></i>

            <div>
              <h3 className="skills__name">Grails</h3>
              <span className="skills__level">Intermediate</span>
            </div>
          </div>
          {/*  */}
        </div>
        {/*  */}
      </div>
    </div>
  );
};

export default Backend;
