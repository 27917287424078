import Image1 from "../../assets/testimonial1.jpg";
import Image2 from "../../assets/testimonial2.jpg";
import Image3 from "../../assets/testimonial3.jpg";

export const Data = [
  {
    id: 1,
    image: Image1,
    title: " George Bernard Shaw",
    description: `"Life isn't about finding yourself. Life is about creating yourself."`,
  },
  {
    id: 2,
    image: Image2,
    title: " William James",
    description: `"If you can change your mind, you can change your life."`,
  },
  {
    id: 3,
    image: Image3,
    title: " Erica Jong",
    description: `"If you don't risk anything, you risk even more."`,
  },
];
