import Work1 from "../../assets/work1.jpg";
import Work2 from "../../assets/work2.jpg";
import Work3 from "../../assets/work3.jpg";
import Work4 from "../../assets/work4.jpg";
import Work5 from "../../assets/work5.jpg";
import Work6 from "../../assets/work6.jpg";

const Menu = [
  {
    id: 1,
    image: Work1,
    title: "Hulu 2.0",
    category: "NextJs",
    link: "https://hulu-2-0-clone-flax.vercel.app/",
  },
  {
    id: 2,
    image: Work2,
    title: "Buzzfeed Clone",
    category: "HTML/CSS",
    link: "https://buzzfeed-clone.vercel.app/",
  },
  {
    id: 3,
    image: Work3,
    title: "Johnny Bravo - Jokes",
    category: "JavaScript",
    link: "https://johnnybravojokes.vercel.app/",
  },
  {
    id: 4,
    image: Work4,
    title: "LinkedIn 2.0",
    category: "React",
    link: "",
  },
  {
    id: 5,
    image: Work5,
    title: "Pizza Palace",
    category: "React",
    link: "https://pizza-palace-teal.vercel.app/",
  },
  {
    id: 6,
    image: Work6,
    title: "Moving Portfolio",
    category: "JavaScript",
    link: "https://react-portfolio-1-psi.vercel.app/",
  },
];

export default Menu;
